<template>
  <div class="treemap" ref="treemap"></div>
</template>

<script>
import TreemapNested from './treemap-nested'

export default {
  name: 'treemap-nested',
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    currencySymbol: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.chart = TreemapNested({
      height: 650,
      container: this.$refs.treemap,
      currencySymbol: this.currencySymbol,
      data: {
        label: '',
        children: [
          ...this.data
        ]
      }
    }).render()
  },
  watch: {
    data (newData) {
      if (newData) {
        this.chart.data({
          label: '',
          children: [
            ...newData
          ]
        })
      }
    }
  }
}
</script>
