import * as d3 from 'd3'

export function thousandsFormat (value) {
  const locale = d3.formatLocale({
    decimal: ',',
    thousands: ' ',
    grouping: [3]
  })
  return locale.format(',')(value)
}
