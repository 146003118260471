/* eslint-disable */

import * as d3 from 'd3'
import patternify from '../d3-assets/patternify'
import { thousandsFormat } from '../d3-assets/formatters'

function TreemapNested (params) {
  var attrs = Object.assign({
    width: 500,
    height: 500,
    margin: {
      top: 20,
      left: 20,
      right: 20,
      bottom: 20
    },
    currencySymbol: null,
    data: [],
    container: 'body'
  }, params)

  let container,
      svg,
      chart,
      chartWidth,
      chartHeight,
      treemap,
      tile = d3.treemapSquarify,
      format = thousandsFormat

  function main() {
    container = d3.select(attrs.container)

    // calculate chart width and height
    setDimensions()

    // initialize required layout such as arc, partition etc.
    setChartParams()

    // add svg and other group elements
    addContainers()

    // draw treemap
    addTreemap()
  }

  function setChartParams () {
    treemap = data => {
      const h = d3.hierarchy(data)
        .sum(d => d.amount)
        .sort((a, b) => b.amount - a.amount)

      const t = d3.treemap()
        .tile(tile)
        .size([chartWidth, chartHeight])
        .paddingOuter(3)
        .paddingTop(21)
        .paddingInner(2)
        .round(true)
      
      return t(h);
    }
  }

  function setDimensions () {
    const bound = container.node().getBoundingClientRect()
    const width = bound.width

    if (width > 0) {
      attrs.width = width
    }

    chartWidth = attrs.width - attrs.margin.left - attrs.margin.right
    chartHeight = attrs.height - attrs.margin.top - attrs.margin.bottom
  }

  function addContainers () {
    svg = patternify({
      container,
      selector: 'treemap-chart-svg',
      tag: 'svg'
    })
    .attr('width', attrs.width)
    .attr('height', attrs.height)

    chart = patternify({
      tag: 'g',
      container: svg,
      selector: 'chart-group'
    })
    .attr('transform', `translate(${attrs.margin.left}, ${attrs.margin.top})`)
  }

  function addTreemap () {
    const root = treemap(attrs.data);

    const filter = patternify({
      tag: 'filter',
      selector: 'filter',
      container: svg
    })
    .attr('id', 'shadow-tremap')

    patternify({
      tag: "feDropShadow",
      selector: "feDropShadow",
      container: filter
    })
      .attr("flood-opacity", 0.3)
      .attr("dx", 0)
      .attr("stdDeviation", 5);

    const d3Group = d3.group(root, d => d.height)
    const [, mapValue] = d3Group
    const leaf = patternify({
      tag: 'g',
      selector: 'leaf',
      data: [{ key: mapValue[0].toString(), values: mapValue[1] }],
      container: chart
    })
    .attr('filter', 'url(#shadow-tremap)')
    // console.log(d3.nest().key(d => d.height).entries(root.descendants().slice(1)))
    
    console.log('d3Group ::: ', )

    const node = patternify({
      tag: 'g',
      selector: 'node',
      data: d => d.values,
      container: leaf
    })
    .attr("transform", d => `translate(${d.x0},${d.y0})`);

    patternify({
      tag: 'title',
      selector: 'title',
      container: node,
      data: d => [d]
    })
      .text(d => `${d.ancestors().reverse().map(d => d.data.label).join("/")}\n${format(d.value)}`);
    
    patternify({
      tag: 'rect',
      selector: 'leaf-rect',
      container: node,
      data: d => [d]
    })
      .each(d => {
        if (d.data.id) {
          d.id = d.data.id;
        } else {
          d.id = d.data.label.split(" ").join("-");
        }
      })
      .attr("id", d => d.id)
      .attr("fill", d => d.data.color)
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", d => d.x1 - d.x0)
      .attr("height", d => d.y1 - d.y0);

    const clipPath = patternify({
      tag: 'clipPath',
      selector: 'clip',
      container: node,
      data: d => [d]
    })
      .attr("id", d => d.clipId = 'clip-' + d.id)
      
    patternify({ 
      tag: 'use',
      selector: 'use',
      container: clipPath,
      data: d => [d]
    })
      .attr("xlink:href", d => '#' + d.id);

    patternify({
      tag: 'text',
      selector: 'leaf-text',
      container: node,
      data: d => [d]
    })
      .attr("clip-path", d => `url(#${d.clipId})`)
      .attr('font-size', '0.8rem')
      .attr('fill', d => {
        if (d.data.textColor) {
          return d.data.textColor;
        }
  
        if (d.parent && d.parent.data.textColor) {
          return d.parent.data.textColor;
        }
        
        return '#000';
      })
      .selectAll("tspan")
      .data(d => {
        const dt = d.data.label.split(/(?=[A-Z][^A-Z])/g);

        let value = format(d.value);
        
        if (attrs.currencySymbol) {
          value += ` ${attrs.currencySymbol}`;
        }

        if (d.data.percent) {
          value += ' (' + d.data.percent + ' %)';
        }

        dt.push(value);

        return dt;
      })
        .join("tspan")
        .text(d => d);

    node.filter(d => d.children).selectAll("tspan")
        .attr("dx", 3)
        .attr("y", 13);
  
    node.filter(d => !d.children).selectAll("tspan")
        .attr("x", 3)
        .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i}em`);
  }

  main.render = function () {
    main()

    const rand = Math.floor(Math.random() * 1000000000);
    d3.select(window).on(`resize.${rand}`, main);

    return main
  }

  main.data = function (data) {
    attrs.data = data;

    setChartParams();
    addTreemap();

    return main;
  }

  return main
}

export default TreemapNested
