<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row>
            <b-col class="chart-container" style="color:white">
              <div>
                <b-form-checkbox v-model="checkbox" name="check-button" switch>
                  Dataset: {{checkbox ? 'sunburstChartData1' : 'sunburstChartData2'}}
                </b-form-checkbox>
              </div>
              <treemap-nested
                :data="sunburstChartData"
                :currencySymbol="'kr'"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="dashboad bg-light page-wrapper">
      <b-row class="main-content-wrapper" no-gutters>
        <b-col>
          <h1>Nested Treemap (n-level hierarchy data)</h1>
          <b>Data:</b><br />
          <div
            style="font-family: 'Courier New';font-size:80%;width:80%;background: white;padding:10px;"
          >
            <pre>{{ sunburstChartData }}</pre>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import treemapNested from '@/components/d3charts/treemap-nested/treemap-nested.vue'

export default {
  components: {
    treemapNested
  },
  data () {
    return {
      sunburstChartData1: [{
        label: 'Conservative bucket',
        color: '#FEC600',
        textColor: '#000',
        id: 'conservative',
        children: [{
          label: 'Equities',
          id: 'conservative_equities',
          color: '#FEC600',
          textColor: '#000',
          amount: '0'
        }, {
          label: 'Cash',
          id: 'conservative_cash',
          color: '#FEC600',
          textColor: '#000',
          amount: 0
        }, {
          label: 'Gold',
          id: 'conservative_gold',
          color: '#FEC600',
          textColor: '#000',
          amount: 0
        }, {
          label: 'Fixed income',
          id: 'conservative_fixed_income',
          color: '#FEC600',
          textColor: '#000',
          amount: 0
        }, {
          label: 'Alternative investments',
          id: 'conservative_alternatives',
          color: '#FEC600',
          textColor: '#000',
          amount: 0
        }, {
          label: 'Not defined',
          id: 'conservative_not_defined',
          color: '#FEC600',
          textColor: '#000',
          amount: 1539719
        }]
      }, {
        label: 'Moderate bucket',
        color: '#ff7c43',
        textColor: '#fff',
        id: 'moderate',
        children: [{
          label: 'Equities',
          id: 'moderate_equities',
          color: '#ff7c43',
          textColor: '#fff',
          amount: '1713729'
        }, {
          label: 'Cash',
          id: 'moderate_cash',
          color: '#ff7c43',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Gold',
          id: 'moderate_gold',
          color: '#ff7c43',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Fixed income',
          id: 'moderate_fixed_income',
          color: '#ff7c43',
          textColor: '#fff',
          amount: 1501525.4
        }, {
          label: 'Alternative investments',
          id: 'moderate_alternatives',
          color: '#ff7c43',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Not defined',
          id: 'moderate_not_defined',
          color: '#ff7c43',
          textColor: '#fff',
          amount: 2850000
        }]
      }, {
        label: 'Aggressive bucket',
        color: '#f95d6a',
        textColor: '#fff',
        id: 'aggressive',
        children: [{
          label: 'Equities',
          id: 'aggressive_equities',
          color: '#f95d6a',
          textColor: '#fff',
          amount: '132442'
        }, {
          label: 'Cash',
          id: 'aggressive_cash',
          color: '#f95d6a',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Gold',
          id: 'aggressive_gold',
          color: '#f95d6a',
          textColor: '#fff',
          amount: 5645.8
        }, {
          label: 'Fixed income',
          id: 'aggressive_fixed_income',
          color: '#f95d6a',
          textColor: '#fff',
          amount: 16937.4
        }, {
          label: 'Alternative investments',
          id: 'aggressive_alternatives',
          color: '#f95d6a',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Not defined',
          id: 'aggressive_not_defined',
          color: '#f95d6a',
          textColor: '#fff',
          amount: 135000
        }]
      }, {
        label: 'Speculation bucket',
        color: '#d45087',
        textColor: '#fff',
        id: 'speculation',
        children: [{
          label: 'Equities',
          id: 'speculation_equities',
          color: '#d45087',
          textColor: '#fff',
          amount: '0'
        }, {
          label: 'Cash',
          id: 'speculation_cash',
          color: '#d45087',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Gold',
          id: 'speculation_gold',
          color: '#d45087',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Fixed income',
          id: 'speculation_fixed_income',
          color: '#d45087',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Alternative investments',
          id: 'speculation_alternatives',
          color: '#d45087',
          textColor: '#fff',
          amount: 0
        }, {
          label: 'Not defined',
          id: 'speculation_not_defined',
          color: '#d45087',
          textColor: '#fff',
          amount: 115000
        }]
      }],
      checkbox: true
    }
  },
  computed: {
    sunburstChartData () {
      return this.checkbox ? this.sunburstChartData1 : this.sunburstChartData2
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  padding-top: 50px;
}
</style>
